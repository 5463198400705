// firebase.js
import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';
import { getAuth } from 'firebase/auth'; 

const firebaseConfig = {
  apiKey: "AIzaSyCzpFJdLQimOWzz_QIU6BEAXNxfvqbr5dc",
  authDomain: "fornettisopron-52eb7.firebaseapp.com",
  projectId: "fornettisopron-52eb7",
  storageBucket: "fornettisopron-52eb7.appspot.com",
  messagingSenderId: "915665352258",
  appId: "1:915665352258:web:d450baeb2c64ad5e84dfdb",
  measurementId: "G-XB779E4XJL"
};

const app = initializeApp(firebaseConfig);
const firestore = getFirestore(app);
const storage = getStorage(app);
const auth = getAuth(app);

export { firestore, storage, app, auth };
