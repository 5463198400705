import React, { useEffect, useState } from 'react';
import { getAuth, onAuthStateChanged, signOut } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import { Button, Card, Container, Row, Col, Modal, Form } from 'react-bootstrap';
import { getFirestore, collection, getDocs, updateDoc, doc, deleteDoc, addDoc } from 'firebase/firestore';
import { deleteObject, getDownloadURL, ref, uploadBytes } from 'firebase/storage';
import { storage } from './firebase';
import "./dashboard.css";

const Dashboard = () => {
  const auth = getAuth();
  const navigate = useNavigate();
  const [products, setProducts] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [formData, setFormData] = useState({
    huname: '',
    dename: '',
    enname: '',
    energy: '',
    img: '',
    weight: '',
    carbonhydrate: '',
    fat: '',
    type: '',
    type2: '',
    akcios: '',
    price:'',
    akciotext:'',
    package:''
  });
  const [file, setFile] = useState(null); // Fájlfeltöltéshez szükséges állapot
  const db = getFirestore();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (!user) {
        navigate('/Admin');
      }
    });

    fetchProducts();

    return () => unsubscribe();
  }, [auth, navigate]);

  const fetchProducts = async () => {
    try {
      const querySnapshot = await getDocs(collection(db, 'products'));
      const productsData = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setProducts(productsData);
    } catch (error) {
      console.error('Error fetching products: ', error);
    }
  };

  const handleLogout = () => {
    signOut(auth)
      .then(() => {
        navigate('/Admin');
      })
      .catch((error) => {
        console.error('Sign out error', error);
      });
  };

  const handleNewProduct = () => {
    // Reset form data
    setFormData({
      huname: '',
      dename: '',
      enname: '',
      energy: '',
      img: '',
      weight: '',
      carbonhydrate: '',
      fat: '',
      type: '',
      type2: '',
      akcios:'',
      price:'',
      akciotext:'',
      package:''
    });
    setFile(null); // Reset file state
    setSelectedProduct(null); // Reset selected product
    setShowModal(true);
  };

  const handleEditProduct = (productId) => {
    const productToEdit = products.find(product => product.id === productId);
    setSelectedProduct(productToEdit);
    setFormData(productToEdit);
    setShowModal(true);
  };

  const deleteProduct = async (productId) => {
    try {
      const confirmation = window.confirm("Biztosan törölni szeretnéd ezt a terméket?");
      if (!confirmation) return; // Ha a felhasználó nem erősítette meg a törlést, ne folytassuk tovább

      const productToDelete = products.find(product => product.id === productId);
      
      // Töröljük a dokumentumot a Firestore-ból
      await deleteDoc(doc(db, 'products', productToDelete.id));
      
      // Ha van kép URL, töröljük a képet a Storage-ból
      if (productToDelete.img) {
        const imageRef = ref(storage, `products/${productToDelete.img}`);
        await deleteObject(imageRef);
      }
  
      // Frissítsük a termékek listáját
      fetchProducts();
      setShowModal(false);
    } catch (error) {
      console.error('Hiba a termék törlésekor: ', error);
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleFileInputChange = (e) => {
    const file = e.target.files[0];
    setFile(file);
  };

  const handleSaveChanges = async () => {
    try {
      let imgUrl = formData.img;
      if (file) {
        const storageRef = ref(storage, `products/${file.name}`);
        await uploadBytes(storageRef, file);
        imgUrl = await getDownloadURL(storageRef);
      }
  
      if (selectedProduct) {
        await updateDoc(doc(db, 'products', selectedProduct.id), { ...formData, img: imgUrl });
      } else {
        await addDoc(collection(db, 'products'), { ...formData, img: imgUrl });
      }
  
      fetchProducts();
      setShowModal(false);
    } catch (error) {
      console.error('Hiba a termék frissítésekor: ', error);
    }
  };

  return (
    <div>
      <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
        <div className="container-fluid">
          <a className="navbar-brand"> Dashboard</a>
          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse justify-content-end" id="navbarNav">
            <div className="navbar-nav">
              <Button variant="primary" className="nav-link me-2" onClick={handleNewProduct}>Új termék létrehozása</Button>
              <Button variant="danger" className="nav-link" onClick={handleLogout}>Kijelentkezés</Button>
            </div>
          </div>
        </div>
      </nav>
      <Container className="mt-4">
        <Row xs={1} md={2} lg={3} xl={4} className="g-4">
          {products.map((product) => (
            <Col key={product.id}>
              <Card>
                <Card.Body>
                  <Card.Title>{product.huname}</Card.Title>
                 
                  <Button onClick={() => handleEditProduct(product.id)}>Módosítás</Button>
                  <Button variant='danger'  onClick={() => deleteProduct(product.id)} id='deletebutton' >Törlés</Button>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      </Container>

      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>{selectedProduct ? "Termék szerkesztése" : "Új termék létrehozása"}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="huname">
              <Form.Label>Magyar Neve</Form.Label>
              <Form.Control type="text" name="huname" value={formData.huname} onChange={handleInputChange} />
            </Form.Group>
            <Form.Group className="mb-3" controlId="dename">
              <Form.Label>Német Neve</Form.Label>
              <Form.Control type="text" name="dename" value={formData.dename} onChange={handleInputChange} />
            </Form.Group>
            <Form.Group className="mb-3" controlId="enname">
              <Form.Label>Angol Neve</Form.Label>
              <Form.Control type="text" name="enname" value={formData.enname} onChange={handleInputChange} />
            </Form.Group>
            <Form.Group className="mb-3" controlId="energy">
              <Form.Label>Energia(kcal/kj)</Form.Label>
              <Form.Control type="text" name="energy" value={formData.energy} onChange={handleInputChange} />
            </Form.Group>
            <Form.Group className="mb-3" controlId="img">
              <Form.Label>Kép</Form.Label>
              <Form.Control type="file" name="img" onChange={handleFileInputChange} />
            </Form.Group>
            <Form.Group className="mb-3" controlId="weight">
              <Form.Label>Súlya</Form.Label>
              <Form.Control type="text" name="weight" value={formData.weight} onChange={handleInputChange} />
            </Form.Group>
            <Form.Group className="mb-3" controlId="carbonhydrate">
              <Form.Label>Szénhidrát</Form.Label>
              <Form.Control type="text" name="carbonhydrate" value={formData.carbonhydrate} onChange={handleInputChange} />
            </Form.Group>
            <Form.Group className="mb-3" controlId="fat">
              <Form.Label>Zsír</Form.Label>
              <Form.Control type="text" name="fat" value={formData.fat} onChange={handleInputChange} />
            </Form.Group>
            <Form.Group className="mb-3" controlId="fat">

            <Form.Group className="mb-3" controlId="price">
              <Form.Label>Ára:</Form.Label>
              <Form.Control type="number" name="price" value={formData.price} onChange={handleInputChange} />
            </Form.Group>

            <Form.Group className="mb-3" controlId="package">
              <Form.Label>DB/DK:</Form.Label>
              <Form.Control type="text" name="package" value={formData.package} onChange={handleInputChange} />
            </Form.Group>


        <Form.Label>Akciós</Form.Label>
        <Form.Control as="select" name="akcios" value={formData.akcios} onChange={handleInputChange}>
          <option value="">Válasszon</option>
          <option value="igen">Igen</option>
          <option value="nem">Nem</option>
        </Form.Control>
      </Form.Group>
      <Form.Group className="mb-3" controlId="akciotext">
              <Form.Label>Akciós Szöveg:</Form.Label>
              <Form.Control type="text" name="akciotext" value={formData.akciotext} onChange={handleInputChange} />
            </Form.Group>
           
              <Form.Group className="mb-3" controlId="type">
                <Form.Label>Típusa</Form.Label>
                <Form.Select name="type" value={formData.type} onChange={handleInputChange}>
                  <option value="pizzaszelet">Pizzaszelet</option>
                  <option value="mini">Mini</option>
                  <option value="fank">Fánk</option>
                  <option value="L">L</option>
                  <option value="XL">XL</option>
                </Form.Select>
              </Form.Group>
         

              { (formData.type=="mini" && (
    <Form.Group className="mb-3" controlId="type2">
        <Form.Label>Másodlagos típus</Form.Label>
        <Form.Select name="type2" value={formData.type2} onChange={handleInputChange}>
            <option value="sos">Sós</option>
            <option value="edes">Édes</option>
        </Form.Select>
    </Form.Group>
))}


          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>Mégse</Button>
          <Button variant="primary" onClick={handleSaveChanges}>Mentés</Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Dashboard;
