import React, { useState } from 'react';
import './login.css'; 
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';
import { Navigate, useNavigate } from 'react-router-dom';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const handleLogin = async () => {
    const auth = getAuth();
    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      setIsLoggedIn(true);
      console.log("Sikeres bejelentkezés");
        navigate("/Dashboard")
    } catch (error) {
      setError(error.message);
      console.error('Hiba történt a bejelentkezés során:', error);
    }
  };

 
  return (
    <div className="login-container">
      <h1>Bejelentkezés</h1>
      {error && <p>Hibás e-mail vagy jelszó!</p>}
      <form>
        <input
          type="email"
          placeholder="E-mail"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <input
          type="password"
          placeholder="Jelszó"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <button type="button" onClick={handleLogin}>Bejelentkezés</button>
      </form>
    </div>
  );
};

export default Login;
