// Popup.js
import './popup.css'
import { useTranslation } from 'react-i18next';
import i18n from './i18n';
import closepng from './xpng.png'
import React, { useEffect } from 'react';

const Popup = ({ onClose, product }) => {
  
  const { t } = useTranslation();

  let productName;
console.log(i18n.languages[0])
  if (i18n.languages[0] === 'en') {
    productName = product.enname;
  } else if (i18n.languages[0] === "hu") {
    productName = product.huname;
  } else if (i18n.languages[0] === 'de') {
    productName = product.dename;
  } else if (i18n.languages[0] === 'en'){
    // Default to English if the language is not recognized
    productName = product.enname;
  }
  console.log(productName)
  useEffect(() => {
    // Az ablak megjelenítésekor eltávolítjuk a görgetést
    document.body.style.overflow = 'hidden';

    // Az ablak bezárásakor visszaállítjuk a görgetést
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, []);

  return (
    <div className="popup-wrapper">
      <div className="popup">
        <div className="popup-content">
          <span className='close' onClick={onClose}><img src={closepng} id='closebutton'/></span>
          
          <div className="product-details">
         
          <h2 id='title'>{productName}</h2>
            
            <li>{t('product_energy')} {product.energy}</li>
            <li>{t('product_fat')} {product.fat}</li>
            <li>{t('product_weight')} {product.weight}</li>
            <li>{t('product_carbonhydrate')} {product.carbonhydrate}</li>
           
            
          </div>
         
          <img src={product.img} alt={product.name} id='productimg' />
          
          {/* Egyéb részletes információk */}
        </div>
      </div>
    </div>
  );
  
  
  
};

export default Popup;
