import React from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Contact from './Contact';
import Login from './Login';
import Products from './Products';
import Slider from './Slider';
import Dashboard from './Dashboard';

function App() {
  return (
    <BrowserRouter>
      <div className="App">
        <header className="App-header">
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/Admin" element={<Login />} />
            <Route path="/Dashboard" element={<Dashboard />} />
          </Routes>
        </header>
      </div>
    </BrowserRouter>
  );
}

function HomePage() {
  return (
    <><center>
      <Slider />
      <Products />
      <Contact />
      </center>
    </>
  );
}

export default App;
