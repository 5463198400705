// Contact.js
import React, { useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram, faFacebook, faTiktok } from '@fortawesome/free-brands-svg-icons';
import linktree from "./linktree.png";
import './contact.css';
import { useTranslation } from 'react-i18next';

const Contact = () => {
  const { t } = useTranslation();
  const contactRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      const element = contactRef.current;
      if (element) {
        const rect = element.getBoundingClientRect();
        const isVisible = rect.top < window.innerHeight - 100;
        if (isVisible) {
          element.classList.add('fadeInUp');
        }
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div ref={contactRef} id='kapcsolat' className="contact-container" style={{ backgroundColor: '#ec6a1ff1', padding: '20px' }}>
      <h2 style={{ color: 'white' }}>{t('contact')}</h2>
      <p style={{ color: 'white' }}>{t('follow_us')}</p>
      

      {/* Ikonok hozzáadása */}
      <div className="social-icons">
      <a href="https://www.instagram.com/fornettisopron/" target="_blank" rel="noopener noreferrer" class="icon-link">
  <FontAwesomeIcon icon={faInstagram} size="2x" className="icon" style={{ color: 'white' }} />
</a>
<a href="https://www.facebook.com/profile.php?id=100064154978224" target="_blank" rel="noopener noreferrer" class="icon-link">
  <FontAwesomeIcon icon={faFacebook} size="2x" className="icon" style={{ color: 'white' }} />
</a>
<a href="https://www.tiktok.com/@fornettisopron" target="_blank" rel="noopener noreferrer" class="icon-link">
  <FontAwesomeIcon icon={faTiktok} size="2x" className="icon" style={{ color: 'white' }} />
</a>
<a href="https://linktr.ee/fornettisopron" target="_blank" rel="noopener noreferrer" class="icon-link">
  <img src={linktree} id="linktree"  className='icon'/>
</a>


       
        <p style={{ color: 'white' }}>


          Ha valamilyen kérdése adódna, akkor <a href="tel:+36306037588" title="+36306037588" id='tel' ><u><b>IDE</b></u></a> kattintva hívjon bizalommal!
         

          <br/>
          Rendelj házhoz <a href="https://www.foodora.hu/restaurant/pkbo/negysajtos-pekseg" title="Foodora" id='tel' ><u><b>IDE</b></u></a> kattintva!
 
        </p>
      </div>

      {/* Google Maps beágyazása */}
      <iframe
        title="Google Maps"
        width="80%"
        height="400"
        frameBorder="0"
        style={{ border: '0' }}
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d167.86032134906006!2d16.5856385779507!3d47.68898087726461!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x476c3daad6beeaeb%3A0x68266271bda2e1c8!2zIjQiIFNhanRvcyBQw6lrc8OpZyAvRm9ybmV0dGkgUMOpa3PDqWcv!5e0!3m2!1shu!2shu!4v1703167600064!5m2!1shu!2shu"
        allowFullScreen
      ></iframe>

      <footer className="footer">
        <p className="text"><a target='_blank' href='https://www.facebook.com/gergely.visnyei/'>Created by GergelyPapa</a></p>
      </footer>
    </div>
  );
};

export default Contact;
