import React, { useState, useEffect } from 'react';
import './slider.css';
import logo from './logo.png';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import logo_fornetti from './logofornetti1.png';

import { useTranslation } from 'react-i18next';
import i18n from './i18n';
import Products from './Products';
import Contact from './Contact';
 
const TypingAnimation = ({ text }) => {
  const [displayText, setDisplayText] = useState('');

  useEffect(() => {
    let index = 0;
    const interval = setInterval(() => {
      if (index <= text.length) {
        setDisplayText(text.substring(0, index));
        index++;
      } else {
        clearInterval(interval);
      }
    }, 80); // Az írássebesség beállítása (ezredmásodpercben)

    return () => clearInterval(interval);
  }, [text]);

  return <h1 className="welcome-text">{displayText}</h1>
};



const images = [
  'https://firebasestorage.googleapis.com/v0/b/fornettisopron-52eb7.appspot.com/o/slider_img%2Ffornetti5.JPG?alt=media&token=29b48c99-dd79-44e1-8e8b-cbedf8f4a95a',
  'https://firebasestorage.googleapis.com/v0/b/fornettisopron-52eb7.appspot.com/o/slider_img%2Ffornetti1.JPG?alt=media&token=bdffcfb7-7725-4ecf-afd7-ac06449f6649',
  'https://firebasestorage.googleapis.com/v0/b/fornettisopron-52eb7.appspot.com/o/slider_img%2Ffornetti2.JPG?alt=media&token=833edfc0-a6cc-4ba9-94d1-20f8047f0cda',
  'https://firebasestorage.googleapis.com/v0/b/fornettisopron-52eb7.appspot.com/o/slider_img%2Ffornetti3.JPG?alt=media&token=8bc0b238-a4f7-48e8-b03d-3cc85fc452f1',
  'https://firebasestorage.googleapis.com/v0/b/fornettisopron-52eb7.appspot.com/o/slider_img%2Ffornetti4.JPG?alt=media&token=79fa0f96-8389-466e-82c0-2dee85b0c72b',
  'https://firebasestorage.googleapis.com/v0/b/fornettisopron-52eb7.appspot.com/o/slider_img%2Ffornetti6.JPG?alt=media&token=b81189aa-01db-4664-b0dc-4112ebfefb36'
];

const Slider = () => {
  const { t } = useTranslation();
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [selectedLanguage, setSelectedLanguage] = useState('hu');

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 5000);

    return () => clearInterval(intervalId);
  }, []);

  const handlePrevClick = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  };


  const handleNextClick = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
  };
  const changeLanguage = (language) => {
    // Implement the logic to set the selected language in your application state

    i18n.changeLanguage(language);
    setSelectedLanguage(language);
    console.log(language)
  };




  return (
    <div>
    <nav className="navbar navbar-expand-lg">
      <div className="container-fluid">
        <a className="navbar-brand" href="#">
          <img src={logo} id="logo" alt="Logo" />
        </a>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNavAltMarkup"
          aria-controls="navbarNavAltMarkup"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarNavAltMarkup">
          <div className="navbar-nav">
            <a className="nav-link" aria-current="page" href="#termekeink">
              {t('navbar_products')}
            </a>
            <a className="nav-link" href="#kapcsolat">
              {t('navbar_contact')}
            </a>
            <a className="nav-link" target='_blank' href="https://www.foodora.hu/restaurant/pkbo/negysajtos-pekseg">
              {t('rendeles')}
            </a>
          </div>
  
          <div className="navbar-nav mx-auto"> 
            <a>
              9400, Sopron, Lackner Kristóf u. 11. Buszvégállomás
            </a>
          </div>
          
          <div className="ms-auto">
            <div className="nav-item dropdown">
              <a
                className="nav-link dropdown-toggle"
                href="#"
                id="navbarDropdown"
                role="button"
                data-bs-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
               {selectedLanguage}
              </a>
              <div className="dropdown-menu dropdown-menu-end" aria-labelledby="navbarDropdown">
                <a
                  className="dropdown-item"
                  href="#"
                  onClick={() => changeLanguage('hu')}
                >
                  HU
                </a>
                <a
                  className="dropdown-item"
                  href="#"
                  onClick={() => changeLanguage('de')}
                >
                  DE
                </a>
                <a
                  className="dropdown-item"
                  href="#"
                  onClick={() => changeLanguage('en')}
                >
                  EN
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </nav>
  
    <div className="slider-container">
      <div className="arrow left" onClick={handlePrevClick}></div>
      <TypingAnimation text={t('title')} />
      <img src={logo_fornetti} id='fonetti_logo' alt='asdasd'/>

      
      <div className="arrow right" onClick={handleNextClick}></div>
      <img
        src={images[currentImageIndex]}
        alt={`Slide ${currentImageIndex + 1}`}
        className="img-fluid full-screen-image"
      />
    </div> 
  </div>
  

   
  );
};

export default Slider;
