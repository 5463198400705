import React, { useState, useEffect } from 'react';
import { firestore } from './firebase';
import { collection, getDocs, where, query } from 'firebase/firestore';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Products.css';
import Popup from './Popup';
import { Link, animateScroll as scroll } from 'react-scroll';
import { useTranslation } from 'react-i18next';
import i18n from './i18n';

const Products = () => {
  const [products, setProducts] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [selectedType, setSelectedType] = useState("minden");
  const [selectedType2, setSelectedType2] = useState("edes");
  const [promoProducts, setPromoProducts] = useState([]);

  const { t } = useTranslation();

  useEffect(() => {
    const fetchPromoProducts = async () => {
      const promoQuery = query(collection(firestore, 'products'), where('akcios', '==', 'igen'));
      const promoSnapshot = await getDocs(promoQuery);
      const promoProductsArray = promoSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setPromoProducts(promoProductsArray);
    };

    fetchPromoProducts();
  }, []);

  useEffect(() => {
    const fetchProducts = async () => {
      const allProductsQuery = collection(firestore, 'products');
      const allProductsSnapshot = await getDocs(allProductsQuery);
      const allProductsArray = allProductsSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      const promoProductsArray = allProductsArray.filter(product => product.akcios === 'igen');
      const regularProductsArray = allProductsArray.filter(product => product.akcios !== 'igen');
  
      setPromoProducts(promoProductsArray);
      setProducts(regularProductsArray);
    };
  
    fetchProducts();
  }, []);
  

  const filteredProducts = selectedType === 'minden' 
    ? [...promoProducts, ...products]
    : [...promoProducts, ...products].filter(product => product.type === selectedType && (selectedType !== 'mini' || product.type2 === selectedType2));

  useEffect(() => {
    const handleScroll = () => {
      const productContainers = document.querySelectorAll('.product-container');

      productContainers.forEach((container) => {
        const rect = container.getBoundingClientRect();
        const isVisible = rect.top <= window.innerHeight - 100;

        if (isVisible) {
          container.classList.add('visible');
        } else {
          container.classList.remove('visible');
        }
      });
    };

    window.addEventListener('scroll', handleScroll);
    handleScroll();

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleProductClick = (product) => {
    setSelectedProduct(product);
  };

  const handleClosePopup = () => {
    setSelectedProduct(null);
  };

  return (
    <div className="container mt-4">
      <div>
        <b>
          <label htmlFor="typeSelect" id="label">{t('filter_label')} </label>
        </b>
        <br />
        <select
          id="typeSelect"
          value={selectedType}
          onChange={(e) => setSelectedType(e.target.value)}
        >
          <option value="minden">{t('every_products')}</option>
          <option value="pizzaszelet">{t('pizza')}</option>
          <option value="mini">Mini</option>
          <option value="fank">{t('donout')}</option>
          <option value="L">L</option>
          <option value="XL">XL</option>
        </select>

        {selectedType === 'mini' && (
          <select
            id="typeSelect"
            value={selectedType2}
            onChange={(e) => setSelectedType2(e.target.value)}
          >
            
            <option  value="edes">{t('sweet')}</option>
            <option value="sos">{t('salt')}</option>
          </select>
        )}
      </div>
      <h2 id="termekeink">{t('navbar_products')}</h2>
      <div className="row">
        {filteredProducts.map((product) => (
          <div key={product.id} className="col-md-4 mb-4 product-container" >
            <div
              className="card product-card"
              onClick={() => handleProductClick(product)}
            >
               <h3 id='akcio_text' style={{ textAlign:"left" }}>
                  
                  {product.akciotext===undefined? " ":
               product.akciotext}</h3>
              <img
                src={product.img}
                alt=""
                className="card-img-top product-image"
              />
              <div className="card-body">
             
                <h3 className="card-title">
                  {i18n.languages[0] === 'hu'
                    ? product.huname
                    : i18n.languages[0] === 'en'
                    ? product.enname
                    : product.dename}
                </h3>
                <h2 id='price'>{product.price} Ft/ {product.package}</h2>
              </div>
            </div>
          </div>
        ))}
      </div>
      {selectedProduct && <Popup onClose={handleClosePopup} product={selectedProduct} />}
    </div>
  );
};

export default Products;
