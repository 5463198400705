// i18n.js
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import translationEN from './locales/en.json';
import translationHU from './locales/hu.json';
import translationDE from './locales/de.json';

const resources = {
  en: {
    translation: translationEN,
  },
  hu: {
    translation: translationHU,
  },
  de: {
    translation: translationDE,
  },
};

const setLanguage = (language) => {
  i18n.changeLanguage(language);
  i18n.languages = [language];
};

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: 'hu', // Alapértelmezett nyelv
    fallbackLng: 'hu',
    keySeparator: false,
    interpolation: {
      escapeValue: false,
    },
    setLanguage, // Hozzáadva a setLanguage függvény
  });

export default i18n;
